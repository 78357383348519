! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);


jQuery(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		extensions: ["position-right"],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("w--open");

		return false;
	});

	$mobileMenu.data('mmenu').bind('closed', function() {
		$mobileMenuTrigger.removeClass("w--open");
	});

	// Add dropdown class to main nav items
	$(".navlinkholder:has('.dropdown-list')").addClass("has-dropdown");

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .nav-link-2").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".navlinkholder").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".navlinkholder").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();

	// $('.navlinkholder.has-dropdown').attr('data-ix',"dropdown-hover");

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-page-container").fitVids({ ignore: "nofit" });
	}

	// Adds .hovered class to parent links when hovered over the dropdown wrapper
	// You must manually create and style the .hover class in Custom Code
	var $nav = $('.dropdown-wrap');
	$nav.hover(
		function() { // hover in
			$(this).children('.nav-link-a').addClass('hovered');
		},
		function() { // hover out
			$(this).children('.nav-link-a').removeClass('hovered');
		}
	);

	// Wrap tables with class table-wrapper to make them responsive on tablet mode
	$('table.menu-table').wrap('<div class="table-wrapper">')

	/**
	 * ----------------------------------------------------------------------
	 * Webflow: Interactions: Init
	 */
	/* eslint-disable */
	Webflow.require('ix').init([
		{"slug":"scrolling-login-drop","name":"Scrolling Login Drop","value":{"style":{},"triggers":[]}},
		{"slug":"search-dropdown","name":"Search Dropdown","value":{"style":{},"triggers":[{"type":"click","selector":".header-search-container","stepsA":[{"wait":"200ms","display":"block","height":"auto","transition":"height 300ms ease 0"}],"stepsB":[{"display":"block","height":"0px","transition":"height 300ms ease 0"}]},{"type":"click","selector":".dropdownmegamenu","stepsA":[{"opacity":0,"transition":"opacity 400ms ease-out-quad 0"}],"stepsB":[]}]}},
		{"slug":"navlink-underline-grow","name":"NavLink Underline Grow","value":{"style":{},"triggers":[{"type":"hover","selector":".navlinkunderline","descend":true,"stepsA":[{"width":"90%","transition":"width 300ms ease-out-quad 0"}],"stepsB":[{"width":"0px","transition":"width 300ms ease-out-quad 0"}]}]}},
		{"slug":"save-mega-fade-in","name":"Save Mega Fade In","value":{"style":{},"triggers":[{"type":"hover","selector":".dropdownmegamenu","stepsA":[{"display":"flex"},{"opacity":1,"transition":"opacity 400ms ease-out-quad 0"}],"stepsB":[]}]}},
		{"slug":"mega-fade-out","name":"Mega Fade Out","value":{"style":{},"triggers":[{"type":"hover","selector":".dropdownmegamenu","stepsA":[{"opacity":0,"transition":"opacity 400ms ease-out-quad 0"}],"stepsB":[]}]}},
		{"slug":"save-mega-fade-out","name":"Save Mega Fade Out","value":{"style":{},"triggers":[{"type":"hover","stepsA":[],"stepsB":[{"opacity":0,"transition":"opacity 400ms ease-out-quad 0"},{"display":"none"}]}]}},
		{"slug":"expand-search-bar","name":"Expand Search Bar","value":{"style":{},"triggers":[]}},
		{"slug":"reveal-mobile-search","name":"Reveal Mobile Search","value":{"style":{},"triggers":[{"type":"click","selector":".mobilesearchblock-2","stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
		{"slug":"for-two-part-button-toggle","name":"For Two Part Button Toggle","value":{"style":{},"triggers":[{"type":"click","selector":".two-part-button-list","siblings":true,"preserve3d":true,"stepsA":[{"transition":"transform 750ms ease 0","x":"-100%","y":"0px","z":"0px"}],"stepsB":[]}]}},
		{"slug":"for-two-part-list-back-button","name":"For Two Part List Back Button","value":{"style":{},"triggers":[{"type":"click","selector":".two-part-button-list","preserve3d":true,"stepsA":[{"transition":"transform 750ms ease 0","x":"100%","y":"0px","z":"0px"}],"stepsB":[]}]}}
	]);
	/* eslint-enable */
});
